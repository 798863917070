<template>
  <div class="wrapper">
    <va-navibar></va-navibar>
    <va-slider :slideMenuItems="slideMenuItems" :inns="inns" title="客栈管理"></va-slider>
    <va-content-wrap></va-content-wrap>
    <!--<footer class="main-footer">
        <div class="float-right d-none d-sm-block">
            <b>Version</b> 6.0
        </div>
        <strong>Copyright &copy; 2015-2019 <a href="http://panorover.com">PanoRover.com</a>.</strong> All rights reserved.
    </footer>-->
    <aside class="control-sidebar control-sidebar-dark">
      <div class="control-sidebar-content p-3">
        <nav v-if="setting" class="nav flex-column">
          <a class="nav-link" :href="setting.siteUrl+'/Inn/'+$route.params.innId">客栈首页</a>
          <a class="nav-link" :href="setting.myUrl">会员中心</a>
          <a v-if="$inRole('管理员')" class="nav-link" :href="setting.adminUrl">管理中心</a>
          <a v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-link" :href="setting.myUrl+'/Public'">公共管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-link" :href="setting.innAdminUrl+'/Inn'">客栈管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-link" :href="setting.innAdminUrl+'/Agent'">代理管理</a>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import axios from 'axios'
  import VANaviBar from '@/components/NaviBar.vue'
  import VASlider from '@/components/Slider.vue'
  import VAContentWrap from '@/components/ContentWrap.vue'
  import store from '@/vuex/store.js'

  export default {
    components: {
      'va-navibar': VANaviBar,
      'va-slider': VASlider,
      'va-content-wrap': VAContentWrap,
    },
    data() {
      return {
        signedIn: false,
        slideMenuItems: null,
        inns: [],
        inn: null,
      }
    },
    methods: {
      ...mapActions([
        //'getNoticesCount',
        //'getNotices',
        //'getMessagesCount',
        //'getMessages',
        'getSetting',
        //'getUser'
      ]),
      loadData: function () {
        this.loadMenu()
      },
      loadMenu: function () {
        this.$axios.get('/Api/InnAdmin/Menus').then((res) => {
          this.slideMenuItems = res.data;
          setTimeout(() => {
            $('.nav-sidebar').Treeview({ accordion: true })
          }, 100)
        }).catch(err => {
          console.log(err)
          if (err) {
            this.$message({
              message: '发生了错误 关闭此消息尝试重新加载',
              type: 'error',
              zIndex: 9999,
              showClose: true,
              duration: -1,
              onClose: () => {
                this.loadData();
              }
            })
          }
        })
      },
      rotuerTo(data) {
        this.$router.push({ name: 'InnHome', params: { innId: data.id } })
      },
    },
    created: function () {
      if (this.$inRole('管理员') || this.$inRole('客栈代理') || this.$inRole('客栈代理员工') || this.$inRole('客栈编辑') || this.$inRole('客栈店主') || this.$inRole('客栈店员')) {
        this.loadData();
      } else {
        this.$router.push({ name: 'NoAccess' })
      }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
      ...mapGetters([
        'setting',
        //'unreadMessagesCount',
        //'unreadNotificationsCount',
        //'remainTasksCount',
        //'currentUser'
      ]),
    },
    beforeRouteEnter(to, from, next) {
      checkInnId(to.params.innId, next)
    },
    beforeRouteUpdate(to, from, next) {
      checkInnId(to.params.innId, next)
    },
    store
  }
  function checkInnId(innId, next) {
    var getinn
    if (innId) {
      console.log('rr', typeof innId)
      var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Inns/${innId}` : `/Api/InnAdmin/Inns/${innId}`;
      getinn = axios.get(url)
    } else {
      next({ name: 'NoAccess' })
      return
    }
    var config = {
      params: {
        pageSize: 100,
      }
    };
    var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Inns` : `/Api/InnAdmin/Inns`;
    axios.get(url, config).then(function (res) {
      var inns = res.data.data;
      getinn.then(function (res) {
        console.log(res)
        window.localStorage.innId = innId;
        next(vm => {
          vm.inns = inns;
          vm.inn = res.data
        })
      }).catch(function (err) {
        console.log(err)
        next({ name: 'NoAccess' })
      })
    })
  }
</script>

<style>
</style>
